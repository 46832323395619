import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer/Footer"

function DSGVO() {
  return (
    <Layout>
      <SEO />

      <div className="py-5">
        <div className="container impressum">
          <h2>Datenschutzerklärung vom 05.07.2020</h2>
          <p>des Sanktionsfrei e.V. für hartzfacts.de</p>
          <p>
            Der Schutz deiner personenbezogenen Daten ist uns ein besonderes
            Anliegen. Du kannst von uns erwarten, dass wir mit deinen Daten
            sensibel und sorgfältig umgehen und für eine hohe Datensicherheit
            sorgen. Wir geben deine Daten nicht weiter! Selbstverständlich
            beachten wir die Vorschriften der DSGVO und werden personenbezogene
            Daten von dir nur in dem in dieser Datenschutzerklärung
            beschriebenen Umfang erheben und verarbeiten. Im Hinblick auf die
            verwendeten Begrifflichkeiten, wie z.B. „Verarbeitung“ oder
            „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der
            Datenschutzgrundverordnung (DSGVO).
          </p>
          <p>
            Mit dieser Erklärung unterrichten wir dich über die
            datenschutzrelevanten Aspekte unserer Website und informieren dich
            über die von uns eingesetzten Maßnahmen, um deine Rechte zu wahren.
            Durch Anklicken der entsprechenden Checkbox bei deiner Registrierung
            für unseren Dienst erklärst du uns in Bezug auf die im Folgenden
            bezeichneten Datenverarbeitungsvorgänge dein Einverständnis
            dahingehend, dass wir deine personenbezogenen Daten erheben,
            verarbeiten und nutzen dürfen. Du kannst dein Einverständnis aber
            selbstverständlich jederzeit widerrufen. Der Widerruf kann per
            E-Mail an{" "}
            <a href="mailto:support@sanktionsfrei.de">
              support@sanktionsfrei.de
            </a>{" "}
            gerichtet werden. Auf Verlangen erhältst du auch eine schriftliche
            Auskunft über die zu deiner Person gespeicherten Daten.
          </p>
          <h3>Verantwortlicher</h3>
          <p>
            Sanktionsfrei e.V.
            <br />
            Am Sudhaus 2<br />
            12053 Berlin
          </p>

          <p>Datenschutzbeauftragte: Thomas Kuhnert </p>
          <h3>Arten der verarbeiteten Daten:</h3>
          <p>- Bestandsdaten (z.B. Namen, Adressen).</p>
          <p>- Kontaktdaten (z.B. E-Mail, Telefonnummern).</p>
          <p>- Inhaltsdaten (z.B. Texteingaben).</p>
          <p>
            - Nutzungsdaten (z.B. besuchte Webseiten, Interesse an Inhalten,
            Zugriffszeiten).
          </p>
          <p>
            - Meta-/Kommunikationsdaten (z.B., Geräte-Informationen,
            IP-Adressen).
          </p>
          <h3>Kategorien betroffener Personen</h3>
          <p>
            Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir
            die betroffenen Personen zusammenfassend auch als „Nutzer“).
          </p>
          <h3>Zweck der Verarbeitung</h3>
          <p>
            - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und
            Inhalte.
          </p>
          <p>
            - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
          </p>
          <p>- Sicherheitsmaßnahmen.</p>
          <p>- Reichweitenmessung/Marketing</p>
          <h3>Verwendete Begrifflichkeiten</h3>
          <p>
            „Personenbezogene Daten“ sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person (im Folgenden
            „betroffene Person“) beziehen; als identifizierbar wird eine
            natürliche Person angesehen, die direkt oder indirekt, insbesondere
            mittels Zuordnung zu einer Kennung wie einem Namen, zu einer
            Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie)
            oder zu einem oder mehreren besonderen Merkmalen identifiziert
            werden kann, die Ausdruck der physischen, physiologischen,
            genetischen, psychischen, wirtschaftlichen, kulturellen oder
            sozialen Identität dieser natürlichen Person sind.
          </p>
          <p>
            „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter
            Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe im
            Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit
            und umfasst praktisch jeden Umgang mit Daten.
          </p>
          <p>
            „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in
            einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung
            zusätzlicher Informationen nicht mehr einer spezifischen betroffenen
            Person zugeordnet werden können, sofern diese zusätzlichen
            Informationen gesondert aufbewahrt werden und technischen und
            organisatorischen Maßnahmen unterliegen, die gewährleisten, dass die
            personenbezogenen Daten nicht einer identifizierten oder
            identifizierbaren natürlichen Person zugewiesen werden.
          </p>
          <p>
            „Profiling“ jede Art der automatisierten Verarbeitung
            personenbezogener Daten, die darin besteht, dass diese
            personenbezogenen Daten verwendet werden, um bestimmte persönliche
            Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten,
            insbesondere um Aspekte bezüglich Arbeitsleistung, wirtschaftliche
            Lage, Gesundheit, persönliche Vorlieben, Interessen,
            Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
            natürlichen Person zu analysieren oder vorherzusagen.
          </p>
          <p>
            Als „Verantwortlicher“ wird die natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die allein oder gemeinsam
            mit anderen über die Zwecke und Mittel der Verarbeitung von
            personenbezogenen Daten entscheidet, bezeichnet.
          </p>
          <p>
            „Auftragsverarbeiter“ eine natürliche oder juristische Person,
            Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten
            im Auftrag des Verantwortlichen verarbeitet.
          </p>
          <h3>Maßgebliche Rechtsgrundlagen</h3>
          <p>
            Nach Maßgabe des Art. 13 DSGVO teilen wir dir die Rechtsgrundlagen
            unserer Datenverarbeitungen mit. Sofern die Rechtsgrundlage in der
            Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die
            Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs.
            1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung
            zur Erfüllung unserer Leistungen und Durchführung vertraglicher
            Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b
            DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung
            unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO,
            und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer
            berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den
            Fall, dass lebenswichtige Interessen der betroffenen Person oder
            einer anderen natürlichen Person eine Verarbeitung personenbezogener
            Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als
            Rechtsgrundlage.
          </p>
          <h3>Sicherheitsmaßnahmen</h3>
          <p>
            Nach Maßgabe des Art. 32 DSGVO treffen wir unter Berücksichtigung
            des Stands der Technik, der Implementierungskosten und der Art, des
            Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der
            unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des
            Risikos für die Rechte und Freiheiten natürlicher Personen,
            geeignete technische und organisatorische Maßnahmen, um ein dem
            Risiko angemessenes Schutzniveau zu gewährleisten.
          </p>
          <p>
            Zu den Maßnahmen gehören insbesondere die Sicherung der
            Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
            Kontrolle des physischen Zugangs zu den Daten, als auch des sie
            betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
            Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren
            eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung
            von Daten und Reaktion auf Gefährdung der Daten gewährleisten.
            Ferner berücksichtigen wir den Schutz personenbezogener Daten
            bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software
            sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch
            Technikgestaltung und durch datenschutzfreundliche Voreinstellungen
            (Art. 25 DSGVO).
          </p>
          <h3>Zusammenarbeit mit Auftragsverarbeitern und Dritten</h3>
          <p>
            Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen
            Personen und Unternehmen (Auftragsverarbeitern oder Dritten)
            offenbaren, sie an diese übermitteln oder ihnen sonst Zugriff auf
            die Daten gewähren, erfolgt dies nur auf Grundlage einer
            gesetzlichen Erlaubnis (z.B. wenn eine Übermittlung der Daten an
            Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b
            DSGVO zur Vertragserfüllung erforderlich ist), du eingewilligt hast,
            eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage
            unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
            Webhostern, etc.).
          </p>
          <p>
            Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines
            sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies
            auf Grundlage des Art. 28 DSGVO.
          </p>
          <h3>Übermittlungen in Drittländer</h3>
          <p>
            Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen
            Union (EU) oder des Europäischen Wirtschaftsraums (EWR)) verarbeiten
            oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder
            Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht,
            erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen
            Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer
            rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten
            Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher
            Erlaubnisse, verarbeiten oder lassen wir die Daten in einem
            Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art.
            44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf
            Grundlage besonderer Garantien, wie der offiziell anerkannten
            Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B.
            für die USA durch das „Privacy Shield“) oder Beachtung offiziell
            anerkannter spezieller vertraglicher Verpflichtungen (so genannte
            „Standardvertragsklauseln“).
          </p>
          <h3>Rechte der betroffenen Personen</h3>
          <p>
            Du hast das Recht, eine Bestätigung darüber zu verlangen, ob
            betreffende Daten verarbeitet werden und auf Auskunft über diese
            Daten sowie auf weitere Informationen und Kopie der Daten
            entsprechend Art. 15 DSGVO.
          </p>
          <p>
            Du hast entsprechend. Art. 16 DSGVO das Recht, die Vervollständigung
            der dich betreffenden Daten oder die Berichtigung der dich
            betreffenden unrichtigen Daten zu verlangen.
          </p>
          <p>
            Du hast nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass
            betreffende Daten unverzüglich gelöscht werden, bzw. alternativ nach
            Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der
            Daten zu verlangen.
          </p>
          <p>
            Du hast &nbsp;das Recht zu verlangen, dass die dich betreffenden
            Daten, die du uns bereitgestellt hast nach Maßgabe des Art. 20 DSGVO
            zu erhalten und deren Übermittlung an andere Verantwortliche zu
            fordern.
          </p>
          <p>
            Du hast ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der
            zuständigen Aufsichtsbehörde einzureichen.
          </p>
          <h3>Widerrufsrecht</h3>
          <p>
            Du hast das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO
            mit Wirkung für die Zukunft zu widerrufen
          </p>
          <h3>Widerspruchsrecht</h3>
          <p>
            Du kannst der künftigen Verarbeitung der dich betreffenden Daten
            nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen. Der
            Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der
            Direktwerbung erfolgen.
          </p>
          <h3>Löschung von Daten</h3>
          <p>
            Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und
            18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt. Sofern
            nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben,
            werden die bei uns gespeicherten Daten gelöscht, sobald sie für ihre
            Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine
            gesetzlichen Aufbewahrungspflichten entgegenstehen. Sofern die Daten
            nicht gelöscht werden, weil sie für andere und gesetzlich zulässige
            Zwecke erforderlich sind, wird deren Verarbeitung eingeschränkt.
            D.h. die Daten werden gesperrt und nicht für andere Zwecke
            verarbeitet. Das gilt z.B. für Daten, die aus handels- oder
            steuerrechtlichen Gründen aufbewahrt werden müssen.
          </p>
          <p>
            Nach gesetzlichen Vorgaben in Deutschland, erfolgt die Aufbewahrung
            insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1
            und 4, Abs. 4 HGB (Bücher, Aufzeichnungen, Lageberichte,
            Buchungsbelege, Handelsbücher, für Besteuerung relevanter
            Unterlagen, etc.) und 6 Jahre gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4
            HGB (Handelsbriefe).
          </p>
          <h3>Erbringung unserer satzungs- und geschäftsgemäßen Leistungen</h3>
          <p>
            Wir verarbeiten die Daten unserer Unterstützer, Interessenten und
            Nutzer entsprechend Art. 6 Abs. 1 lit. b. DSGVO, sofern wir ihnen
            gegenüber vertragliche Leistungen anbieten oder im Rahmen
            bestehender geschäftlicher Beziehung, z.B. gegenüber Mitgliedern,
            tätig werden oder selbst Empfänger von Leistungen und Zuwendungen
            sind. Im Übrigen verarbeiten wir die Daten betroffener Personen gem.
            Art. 6 Abs. 1 lit. f. DSGVO auf Grundlage unserer berechtigten
            Interessen, z.B. wenn es sich um administrative Aufgaben oder
            Öffentlichkeitsarbeit handelt.
          </p>
          <p>
            Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck
            und die Erforderlichkeit ihrer Verarbeitung bestimmen sich nach dem
            zugrundeliegenden Vertragsverhältnis. Dazu gehören grundsätzlich
            Bestands- und Stammdaten der Personen (z.B., Name, Adresse, etc.),
            als auch die Kontaktdaten (z.B., E-Mailadresse, Telefon, etc.), die
            Vertragsdaten (z.B., in Anspruch genommene Leistungen, mitgeteilte
            Inhalte und Informationen, Namen von Kontaktpersonen) und sofern wir
            zahlungspflichtige Leistungen oder Produkte anbieten, Zahlungsdaten
            (z.B., Bankverbindung, Zahlungshistorie, etc.).
          </p>
          <p>
            Wir löschen Daten, die zur Erbringung unserer satzungs- und
            geschäftsmäßigen Zwecke nicht mehr erforderlich sind. Dies bestimmt
            sich entsprechend der jeweiligen Aufgaben und vertraglichen
            Beziehungen. Im Fall geschäftlicher Verarbeitung bewahren wir die
            Daten so lange auf, wie sie zur Geschäftsabwicklung, als auch im
            Hinblick auf etwaige Gewährleistungs- oder Haftungspflichten
            relevant sein können. Die Erforderlichkeit der Aufbewahrung der
            Daten wird alle drei Jahre überprüft; im Übrigen gelten die
            gesetzlichen Aufbewahrungspflichten.
          </p>
          <h3>Kontaktaufnahme</h3>
          <p>
            Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail,
            Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur
            Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs.
            1 lit. b) DSGVO verarbeitet.
          </p>
          <p>
            Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind.
            Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten
            die gesetzlichen Archivierungspflichten.
          </p>
          <h3>Newsletter</h3>
          <p>
            Mit den nachfolgenden Hinweisen informieren wir dich über die
            Inhalte unseres Newsletters sowie das Anmelde-, Versand- und das
            statistische Auswertungsverfahren sowie deine Widerspruchsrechte
            auf. Indem du unseren Newsletter abonnierst, erklären du dich mit
            dem Empfang und den beschriebenen Verfahren einverstanden.
          </p>
          <p>
            Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und
            weitere elektronische Benachrichtigungen mit werblichen
            Informationen (nachfolgend „Newsletter“) nur mit der Einwilligung
            der Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen
            einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben
            werden, sind sie für die Einwilligung der Nutzer maßgeblich. Im
            Übrigen enthalten unsere Newsletter Informationen zu unseren
            Leistungen und uns.
          </p>
          <p>
            Double-Opt-In und Protokollierung: Die Anmeldung zu unserem
            Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie
            erhalten nach der Anmeldung eine E-Mail, in der Sie um die
            Bestätigung Ihrer Anmeldung gebeten werden. Diese Bestätigung ist
            notwendig, damit sich niemand mit fremden E-Mailadressen anmelden
            kann. Die Anmeldungen zum Newsletter werden protokolliert, um den
            Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen
            zu können. Hierzu gehört die Speicherung des Anmelde- und des
            Bestätigungszeitpunkts, als auch der IP-Adresse. Ebenso werden die
            Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten
            protokolliert.
          </p>
          <p>
            Anmeldedaten: Um sich für den Newsletter anzumelden, reicht es aus,
            wenn du deine E-Mailadresse angibst.
          </p>
          <p>
            Der Versand des Newsletters und die mit ihm verbundene
            Erfolgsmessung erfolgen auf Grundlage einer Einwilligung der
            Empfänger gem. Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m § 7 Abs. 2
            Nr. 3 UWG oder falls eine Einwilligung nicht erforderlich ist, auf
            Grundlage unserer berechtigten Interessen am Direktmarketing gem.
            Art. 6 Abs. 1 lt. f. DSGVO i.V.m. § 7 Abs. 3 UWG.
          </p>
          <p>
            Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage
            unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO.
            Unser Interesse richtet sich auf den Einsatz eines
            nutzerfreundlichen sowie sicheren Newslettersystems, das sowohl
            unseren geschäftlichen Interessen dient, als auch den Erwartungen
            der Nutzer entspricht und uns ferner den Nachweis von Einwilligungen
            erlaubt.
          </p>
          <p>
            Kündigung/Widerruf - Du kannst den Empfang unseres Newsletters
            jederzeit kündigen. Einen Link zur Kündigung des Newsletters findest
            du am Ende eines jeden Newsletters. Wir können die ausgetragenen
            E-Mailadressen bis zu drei Jahren auf Grundlage unserer berechtigten
            Interessen speichern bevor wir sie löschen, um eine ehemals gegebene
            Einwilligung nachweisen zu können. Die Verarbeitung dieser Daten
            wird auf den Zweck einer möglichen Abwehr von Ansprüchen beschränkt.
            Ein individueller Löschungsantrag ist jederzeit möglich, sofern
            zugleich das ehemalige Bestehen einer Einwilligung bestätigt wird.
          </p>
          <h3>Newsletter - Mailchimp</h3>
          <p>
            Der Versand der Newsletter erfolgt mittels des Versanddienstleisters
            „MailChimp“, einer Newsletterversandplattform des US-Anbieters
            Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta,
            GA 30308, USA. Die Datenschutzbestimmungen des Versanddienstleisters
            können Sie <a href="https://mailchimp.com/legal/privacy/">hier</a>{" "}
            einsehen. The Rocket Science Group LLC d/b/a MailChimp ist unter dem{" "}
            <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&amp;status=Active">
              Privacy-Shield-Abkommen
            </a>
            <span style={{ fontWeight: "400" }}>
              {" "}
              &nbsp;zertifiziert und bietet hierdurch eine Garantie, das
              europäisches Datenschutzniveau einzuhalten. Der
              Versanddienstleister wird auf Grundlage unserer berechtigten
              Interessen gem. Art. 6 Abs. 1 lit. f DSGVO und eines
              Auftragsverarbeitungsvertrages gem. Art. 28 Abs. 3 S. 1 DSGVO
              eingesetzt.
            </span>
          </p>
          <p>
            Der Versanddienstleister kann die Daten der Empfänger in pseudonymer
            Form, d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung oder
            Verbesserung der eigenen Services nutzen, z.B. zur technischen
            Optimierung des Versandes und der Darstellung der Newsletter oder
            für statistische Zwecke verwenden. Der Versanddienstleister nutzt
            die Daten unserer Newsletterempfänger jedoch nicht, um diese selbst
            anzuschreiben oder um die Daten an Dritte weiterzugeben.
          </p>
          <h3>Newsletter - Erfolgsmessung</h3>
          <p>
            Die Newsletter enthalten einen sog. „web-beacon“, d.h. eine
            pixelgroße Datei, die beim Öffnen des Newsletters von unserem
            Server, bzw. sofern wir einen Versanddienstleister einsetzen, von
            dessen Server abgerufen wird. Im Rahmen dieses Abrufs werden
            zunächst technische Informationen, wie Informationen zum Browser und
            Ihrem System, als auch deine IP-Adresse und Zeitpunkt des Abrufs
            erhoben.
          </p>
          <p>
            Diese Informationen werden zur technischen Verbesserung der Services
            anhand der technischen Daten oder der Zielgruppen und ihres
            Leseverhaltens anhand derer Abruforte (die mit Hilfe der IP-Adresse
            bestimmbar sind) oder der Zugriffszeiten genutzt. Zu den
            statistischen Erhebungen gehört ebenfalls die Feststellung, ob die
            Newsletter geöffnet werden, wann sie geöffnet werden und welche
            Links geklickt werden. Diese Informationen können aus technischen
            Gründen zwar den einzelnen Newsletterempfängern zugeordnet werden.
            Es ist jedoch weder unser Bestreben, noch, sofern eingesetzt, das
            des Versanddienstleisters, einzelne Nutzer zu beobachten. Die
            Auswertungen dienen uns dazu, die Lesegewohnheiten unserer Nutzer zu
            erkennen und unsere Inhalte auf sie anzupassen oder unterschiedliche
            Inhalte entsprechend den Interessen unserer Nutzer zu versenden.
          </p>
          <p>
            Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich,
            in diesem Fall muss das gesamte Newsletterabonnement gekündigt
            werden.
          </p>
          <h3>Hosting und E-Mail-Versand</h3>
          <p>
            Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
            Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
            Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
            Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie
            technische Wartungsleistungen, die wir zum Zwecke des Betriebs
            dieses Onlineangebotes einsetzen.
          </p>
          <p>
            Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
            Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
            Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
            Onlineangebotes auf Grundlage unserer berechtigten Interessen an
            einer effizienten und sicheren Zurverfügungstellung dieses
            Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO
            (Abschluss Auftragsverarbeitungsvertrag).
          </p>
          <h3>Erhebung von Zugriffsdaten und Logfiles</h3>
          <p>
            Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
            berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO
            Daten über jeden Zugriff auf den Server, auf dem sich dieser Dienst
            befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören
            Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs,
            übertragene Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp
            nebst Version, das Betriebssystem des Nutzers, Referrer URL (die
            zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
          </p>
          <p>
            Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur
            Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer
            von maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren
            weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur
            endgültigen Klärung des jeweiligen Vorfalls von der Löschung
            ausgenommen.
          </p>
          <h3>Content-Delivery-Network von Cloudflare</h3>
          <p>
            Wir setzen ein so genanntes "Content Delivery Network" (CDN),
            angeboten von Cloudflare, Inc., 101 Townsend St, San Francisco, CA
            94107, USA, ein. Cloudflare ist unter dem{" "}
            <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;status=Active">
              Privacy-Shield-Abkommen
            </a>
            <span style={{ fontWeight: "400" }}>
              {" "}
              zertifiziert und bietet hierdurch eine Garantie, das europäische
              Datenschutzrecht einzuhalten.
            </span>
          </p>
          <p>
            Ein CDN ist ein Dienst, mit dessen Hilfe Inhalte unseres
            Onlineangebotes, insbesondere große Mediendateien, wie Grafiken oder
            Skripte mit Hilfe regional verteilter und über das Internet
            verbundener Server, schneller ausgeliefert werden. Die Verarbeitung
            der Daten der Nutzer erfolgt alleine zu den vorgenannten Zecken und
            der Aufrechterhaltung der Sicherheit und Funktionsfähigkeit des CDN.
          </p>
          <p>
            Die Nutzung erfolgt auf Grundlage unserer berechtigten Interessen,
            d.h. Interesse an einer sicheren und effizienten Bereitstellung,
            Analyse sowie Optimierung unseres Onlineangebotes gem. Art. 6 Abs. 1
            lit. f. DSGVO.
          </p>
          <p>
            Weitere Informationen finden Sie in der{" "}
            <a href="https://www.cloudflare.com/security-policy">
              Datenschutzerklärung von Cloudflare
            </a>
            .
          </p>

          <h3>Reichweitenanalyse mit Matomo</h3>
          <p>
            {" "}
            Für die Reichweitenanalyse auf Grundlage unserer berechtigten
            Interessen verwenden wir Matomo (d.h. Interesse an der Analyse und
            Optimierung unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit.
            f. DSGVO) ES werden die folgenden Daten verarbeitet: dein
            verwendeter Browsertyp und die -version, dein Betriebssystem, dein
            Herkunftsland und Datum und Uhrzeit der Serveranfrage, die Anzahl
            der Besuche, deine Verweildauer auf der Website sowie die von dir
            betätigten externen Links. Die IP-Adresse der Nutzer wird
            anonymisiert, bevor sie gespeichert wird.{" "}
          </p>

          <p>
            {" "}
            Matomo verwendet Cookies, die auf dem Computer der Nutzer
            gespeichert werden und die eine Analyse der Benutzung unseres
            Onlineangebotes durch die Nutzer ermöglichen. Dabei können aus den
            verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt
            werden. Die Cookies haben eine Speicherdauer von einer Woche. Die
            durch das Cookie erzeugten Informationen über Ihre Benutzung dieser
            Webseite werden nur auf unserem Server gespeichert und nicht an
            Dritte weitergegeben.{" "}
          </p>

          <p>
            {" "}
            Du kannst der anonymisierten Datenerhebung durch das Programm Matomo
            jederzeit mit Wirkung für die Zukunft widersprechen, indem du auf
            den untenstehenden Link klickst. In diesem Fall wird in ihrem
            Browser ein sog. Opt-Out-Cookie abgelegt, was zur Folge hat, dass
            Matomo keinerlei Sitzungsdaten mehr erhebt. Wenn du deine Cookies
            löschst, so hat dies jedoch zur Folge, dass auch das Opt-Out-Cookie
            gelöscht wird und daher von dir erneut aktiviert werden muss.
          </p>

          <p>
            {" "}
            Die Logs mit den Daten der Nutzer werden nach spätestens 6 Monaten
            gelöscht.{" "}
          </p>
          <iframe
            style={{
              margin: "-8px",
              paddingTop: "30px",
              border: "0",
              width: "100%",
            }}
            src="https://piwik.thomaskuhnert.com/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=ffffff&fontColor=5E6073&fontSize=18px&fontFamily=Lato"
          ></iframe>
        </div>
      </div>

      <Footer />
    </Layout>
  )
}

export default DSGVO
